import React, { useState } from 'react';
import {originalAudioFiles} from '../audioFiles';
import playAudio from '../playAudio';
import useStateWithLocalStorage from '../useStateWithLocalStorage';
import { TextBlock } from './TextBlock';

export function IsHetBierTijd() {
    const [lastPlayedTitle, setLastPlayedTitle] = useState(null);
    const [total, setTotal] = useStateWithLocalStorage('SoundBoard.IsHetBierTijd.total', 0);

    const handleClick = () => {
        var randomItem = originalAudioFiles[Math.floor(Math.random() * originalAudioFiles.length)];
        setLastPlayedTitle(randomItem.title);
        setTotal(total + 1);
        playAudio(randomItem.src);
    }

    return (
        <div>
            <p>
                <button className="btn btn-primary btn-lg btn-transparent"
                    onClick={() => handleClick()}>Vraag het aan Bob!</button>
            </p>
            {lastPlayedTitle !== null &&
                <TextBlock style={{fontSize: "larger"}}>Bob zegt: {lastPlayedTitle}</TextBlock>
            }
            <TextBlock>Bob heeft in totaal {total} keer je vraag beantwoord&thinsp;!</TextBlock>
        </div>
    );
}