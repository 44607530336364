import React from 'react';
import './Wordart.scss';

export function WordArt(props) {
    return (<section className="style-eleven">
        <div className="wordart text-no-select" style={{overflow: "hidden"}}>
            {/* need to put text in data-content for shadow style (:fbefore element) */}
            <h1 className="preview" data-content={props.text} style={{paddingBottom:0}}>
                {props.text}
            </h1>
        </div>
    </section>);
}
