import React, { useState } from 'react';
import { WordArt } from './components/WordArt';
import { SoundBoard } from './components/SoundBoard';
import { IsHetBierTijd } from './components/IsHetBierTijd';
import { YoutubeIFrame } from './components/YoutubeIFrame';
import { AppBackground } from './components/AppBackground/AppBackground';
import { ShareButtons } from './components/ShareButtons/ShareButtons';
import { PreloadAudio } from './components/PreloadAudio';
import { TextBlock } from './components/TextBlock';



function App() {
    const [bgUseLaser, setBgUserLaser] = useState(false);
    const handlePointerDown = event => {
        setBgUserLaser(true);
        if (event.pointerType !== "mouse") {
            // Android chrome often fires pointerUp too fast (no effect) or does not fire pointerUp at all.
            // So use a timer for mobile
            setTimeout(() => { setBgUserLaser(false); }, 200);
        };
    }
    const handlePointerUp = event => {
        if (event.pointerType === "mouse") {
            setBgUserLaser(false);
        };
    }
    return (<div className="site-layout" onPointerDown={e => handlePointerDown(e)} onPointerUp={e => handlePointerUp(e)}>
        <AppBackground useLaser={bgUseLaser} />
        <PreloadAudio />
        <ShareButtons />

        <div className="text-center site-layout__biertijd">
            <WordArt text="Is het al biertijd?" />
            <IsHetBierTijd />
        </div>

        <div className="text-center site-layout__soundboard">
            <WordArt text="Luister naar Bob!" />
            <SoundBoard />
        </div>

        <div className="text-center site-layout__videos">
            <div style={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
                <YoutubeIFrame embedId="t9k_eqdpAiQ" startAt="44" title="Willems Kantine 2006 Jos (deel1/2)" />
                {/* <YoutubeIFrame embedId="hd-P47vO-Rw" title="Bob de Huisbaas - Op je muil gauw!" /> */}
                <YoutubeIFrame embedId="45WoVNLU0uA" title="Huisbaas bob hardstyle remix" />
                <YoutubeIFrame embedId="auxxzW1Zlb8" title="Bob de huisbaas + Wat heb je = Thug Life" />
                {/* <YoutubeIFrame embedId="phMbWFz-Bn0" title="Doe Jos eens vijf euro's geven promo" /> */}
                <YoutubeIFrame embedId="2KcRCxZsBfk" title="Baudet en Klaver / Bob de huisbaas en Jos" />

                <div style={{ marginBottom: '20px' }}>
                    <TextBlock>Wil je weten waar Bob nu is?</TextBlock>
                    {/* Rickroll :D */}
                    <a className={`btn btn-primary`} href="https://youtu.be/dQw4w9WgXcQ" 
                       target="_blank" rel="noopener noreferrer">
                        Klik hier voor de video&thinsp;!
                    </a>
                </div>
            </div>
        </div>
        {/* <div className="text-center">
            <p>
                <a href="http://doejoseensvijfeurosgeven.nl/" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-transparent">
                    Doe Jos eens 5 euro's geven!</a>
            </p>
        </div> */}
    </div >);
}

export default App;