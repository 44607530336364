import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';
import { preloadAllAudio } from '../playAudio';


export function PreloadAudio(props) {

    const [show, setShow] = useState(true);
    const [progress, setProgress] = useState(null);

    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);

    const preloadProgressCallback = progress => {
        // create copy of obj to change state
        setProgress({ ...progress });
        if (progress.getIsLoaded()) {
            setTimeout(() => {
                // comment this line to keep showing modal
                setShow(false);
            }, 250);
        }
    }
    useEffect(() => {
        preloadAllAudio(preloadProgressCallback);
        // [] param: ensure the useEffect only runs once https://css-tricks.com/run-useeffect-only-once/
    }, []);

    if (progress === null) {
        // prevent null exceptions in template below
        return (null);
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Bob komt eraan...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center mb-3">
                    <Spinner animation="border" variant="primary" />
                </div>
                <ProgressBar now={progress.loadedCount} max={progress.totalCount} />
                {progress.errors.map(error =>
                    <p className="text-danger">
                        <small>{error}</small>
                    </p>
                )}
                {/* <pre>
                    {JSON.stringify(progress, null, "    ")}
                </pre> */}
            </Modal.Body>
            <Modal.Footer style={{ 'justifyContent': 'center' }}>
                <Button variant={progress.getIsLoaded() ? "primary" : "secondary"} className="btn-transparent" onClick={handleClose}>
                    U ken doorgaan
            </Button>
            </Modal.Footer>
        </Modal>
    );

    // return (
    //     <>
    //         <Button variant="primary" onClick={handleShow}>
    //             Launch demo modal
    //         </Button>

    //         <Modal show={show} onHide={handleClose}>
    //             <Modal.Header closeButton>
    //                 <Modal.Title>Modal heading</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
    //             <Modal.Footer>
    //                 <Button variant="secondary" onClick={handleClose}>
    //                     U ken doorgaan
    //         </Button>
    //                 <Button variant="primary" onClick={handleClose}>
    //                     U ken doorgaan
    //         </Button>
    //             </Modal.Footer>
    //         </Modal>
    //     </>
    // );
}