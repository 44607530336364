import React from 'react';
import useStateWithLocalStorage from '../useStateWithLocalStorage';

export function SoundBoardButton(props) {
    const showClickCount = props.showClickCount || true;
    const [clickCount, setClickCount] = useStateWithLocalStorage(`SoundBoard.${props.name}.clickCount`, 0);

    const handleClick = () => {
        props.handleClick();
        setClickCount(clickCount + 1);
    }
    return (
        <button className={`btn btn-transparent ${props.btnStyle}`} onClick={handleClick}>
            {props.name}
            {showClickCount === true && clickCount > 0 &&
                <span>
                    &nbsp;({clickCount}x)
                </span>
            }
        </button>
    );
}