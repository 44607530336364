import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import audioFiles, { originalAudioFiles, mandAudioFiles, mashupAudioFiles } from '../audioFiles';
import playAudio from '../playAudio';
import useStateWithLocalStorage from '../useStateWithLocalStorage';
import { SoundBoardButton } from './SoundBoardButton';
import { TextBlock } from './TextBlock';
import './SoundBoard.scss';



const buttonStyles = ["btn-primary", "btn-info", "btn-success", "btn-warning", "btn-danger"];
const getButtonStyle = (index) => {
    index = index % buttonStyles.length;
    return buttonStyles[index];
}


export function SoundBoard(props) {
    const [history, setHistory] = useState([]);
    const [total, setTotal] = useStateWithLocalStorage('SoundBoard.total', 0);
    const handleButtonClick = (audioFile, playOverlapping) => {
        setHistory(history.concat(audioFile.title));
        setTotal(total + 1);
        // playOverlapping is more fun for the short mand clips :)
        playAudio(audioFile.src, playOverlapping);
    };
    const historyReversedCopy = history.slice().reverse();

    const createNormalButtons = audioSet =>
        audioSet.map((audioFile, index) =>
            <SoundBoardButton key={audioFile.src} name={audioFile.title}
                btnStyle={"m-1 " + getButtonStyle(index)}
                handleClick={() => handleButtonClick(audioFile, false)} />
        );
    const createMandButtons = audioSet => {
        let mandButtonStyleIndex = 0;
        let getMandButtonStyle = (audioFile, index) => {
            if (audioFile.startNewButtonColor === true && index > 0) {
                mandButtonStyleIndex += 1;
            }
            return getButtonStyle(mandButtonStyleIndex);
        }
        return audioSet.map((audioFile, index) => 
            <SoundBoardButton key={audioFile.src} name={audioFile.title}
                btnStyle={getMandButtonStyle(audioFile, index)} showClickCount="false"
                handleClick={() => handleButtonClick(audioFile, true)} />
        )
    };

    return (
        <div className='SoundBoard'>
            <TextBlock>Je hebt in totaal {total} keer naar Bob geluisterd&thinsp;!</TextBlock>

            <Tabs defaultActiveKey="standaard" variant="pills" id="SoundBoard-tabs">
                <Tab eventKey="description" title="Kies een set:" disabled>
                </Tab>
                <Tab eventKey="standaard" title="Standaard">
                    {createNormalButtons(originalAudioFiles)}
                </Tab>
                <Tab eventKey="mand" title="Mand">
                    <div className="mand-container">
                        {createMandButtons(mandAudioFiles)}
                    </div>
                </Tab>
                <Tab eventKey="mashup" title="Mashup">
                    {createNormalButtons(mashupAudioFiles)}
                </Tab>
            </Tabs>



            {history.length > 0 &&
                <TextBlock tag="h3" style={{ marginTop: '20px' }}>Geluisterd naar:</TextBlock>
            }

            <ul className="list-unstyled">
                {historyReversedCopy.map((value, index) =>
                    <li key={index}>
                        <TextBlock tag="span">{value}</TextBlock>
                    </li>
                )}
            </ul>
        </div>);
}
