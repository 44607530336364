import React from 'react';
import './YoutubeIFrame.scss';

export function YoutubeIFrame(props) {
    let src = "https://www.youtube.com/embed/" + props.embedId;
    if (props.startAt) {
        src += "?start=" + props.startAt;
    }
    return (
        <div className="YoutubeIFrame" style={{marginBottom: '10px'}}>
            <iframe width="560" height="315" src={src} title={props.title} frameBorder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
            </iframe>
        </div>
    );
}
