import React from 'react';
import store from 'store';

// combination of https://www.robinwieruch.de/local-storage-react and https://github.com/marcuswestin/store.js/
export default function useStateWithLocalStorage(localStorageKey, defaultValue) {
  const [value, setValue] = React.useState(
    store.get(localStorageKey) || defaultValue
  );
  React.useEffect(() => {
    store.set(localStorageKey, value);
  }, [localStorageKey, value]);
  return [value, setValue];
};