import { Howl } from 'howler';
import audioFiles from './audioFiles'

var currentHowl = null;

const playAudio = (src, playOverlapping) => {
    // playOverlapping is more fun for the short mand clips :)
    if (!playOverlapping && currentHowl != null) {
        currentHowl.stop();
    }
    currentHowl = new Howl({
        src: [src]
    });
    currentHowl.play();
};

export default playAudio;


export function preloadAudio(src, onLoad, onLoadError) {
    new Howl({
        src: [src],
        // Fires when the sound is loaded.
        onload: () => onLoad(src),
        // Fires when the sound is unable to load. The first parameter is the ID of the sound (if it exists) and the second is the error message/code.
        onloaderror: (id, error) => onLoadError(src, id, error)
    });
}
export function preloadAllAudio(progressCallBack) {
    const totalCount = audioFiles.flatMap(audioSet => audioSet.files).length;
    //console.log(totalCount + "files");

    let progress = {
        loadedCount: 0,
        totalCount: totalCount,
        errors: []
    }
    progress.getIsLoaded = () => progress.loadedCount >= totalCount;
    progress.getProgressPct = () => progress.loadedCount / totalCount;

    const onLoad = function(src){
        //console.log(`onLoad: '${src}'`);
        progress.loadedCount += 1;
        progressCallBack(progress);
    }
    const onLoadError = function(src, id, error){
        console.log(`onLoadError: '${src}' = ${id} --> ${error}`);
        progress.loadedCount += 1;
        progress.errors.push(`${src}: ${error}`);
        progressCallBack(progress);
    }
    progressCallBack(progress);

    audioFiles.forEach(audioSet => {
        audioSet.files.forEach(file => {
            preloadAudio(file.src, onLoad, onLoadError)
        });
    });
}
