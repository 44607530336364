// ------------- support IE11 voor Bé :) -------------
// https://www.npmjs.com/package/react-app-polyfill 
// https://stackoverflow.com/questions/55967048/how-do-i-support-ie-11-with-create-react-app-3-0 
// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// --------------------------------------------------


// General styles for index and App (including bootstrap), NOT for components
import './css/index.scss';



import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
