
export const audioBasePath = process.env.PUBLIC_URL + "/assets/audio/";

export const originalAudioFiles = [
      { fileName: "5 euro's.. Op je muil, gauw!.mp3", title: "5 euro's.. Op je muil, gauw!" }
    , { fileName: "Allemaal loze putten.mp3", title: "Allemaal loze putten" }
    , { fileName: "Allemaal vuilniszakken.mp3", title: "Allemaal vuilniszakken" }
    , { fileName: "Als je nou je herses gebruik.mp3", title: "Als je nou je herses gebruik" }
    , { fileName: "Als je nou opgepas had.mp3", title: "Als je nou opgepas had" }
    , { fileName: "Begodverredomd van huurders.mp3", title: "Begodverredomd van huurders" }
    , { fileName: "Can you hear me.mp3", title: "Can you hear me" }
    , { fileName: "Dan gaat u der achtermekaar uit.mp3", title: "Dan gaat u der achtermekaar uit" }
    , { fileName: "Dan ken je alleen geen biertje kopuuh.mp3", title: "Dan ken je alleen geen biertje kopuuh" }
    , { fileName: "Dat is je huisbaas, die vraagt om z'n centen.mp3", title: "Dat is je huisbaas, die vraagt om z'n centen" }
    , { fileName: "Dat is wel mooi natuurlijk.mp3", title: "Dat is wel mooi natuurlijk" }
    , { fileName: "Dat komt niet uit met hem.mp3", title: "Dat komt niet uit met hem" }
    , { fileName: "Denkt erom hoor, dat ik er 1 ben.mp3", title: "Denkt erom hoor, dat ik er 1 ben" }
    , { fileName: "Die heb niks meer joh.mp3", title: "Die heb niks meer joh" }
    , { fileName: "Die man stink.mp3", title: "Die man stink" }
    , { fileName: "Dááágg.mp3", title: "Dááágg" }
//    , { fileName: "Deze bestaat niet.mp3", title: "TEST (werkt niet)" }
    , { fileName: "Elke zweetneus van je sokken....mp3", title: "Elke zweetneus van je sokken..." }
    , { fileName: "En jij krijg ook een tik op je reetos, wha ha ha hááá.mp3", title: "En jij krijg ook een tik op je reetos, wha ha ha hááá" }
    , { fileName: "Geef eens antwoord.mp3", title: "Geef eens antwoord" }
    , { fileName: "Gha ha ha ha.mp3", title: "Gha ha ha ha" }
    , { fileName: "Hoe is het met die paarduuh, hoeveel ga je er kopuuh.mp3", title: "Hoe is het met die paarduuh, hoeveel ga je er kopuuh" }
    , { fileName: "I'll be back.mp3", title: "I'll be back" }
    , { fileName: "Iedereen moet betalen...iedereen.mp3", title: "Iedereen moet betalen...iedereen" }
    , { fileName: "Ik kom wel terug hoooor.mp3", title: "Ik kom wel terug hoooor" }
    , { fileName: "Ja, Ja, Ja, wel waar Jos.mp3", title: "Ja, Ja, Ja, wel waar Jos" }
    , { fileName: "Jaa, dan is het weer haast een maand om héé.mp3", title: "Jaa, dan is het weer haast een maand om héé" }
    , { fileName: "Je heb altijd een grote muil gehad.mp3", title: "Je heb altijd een grote muil gehad" }
    , { fileName: "Je heb een stuk in je broek.mp3", title: "Je heb een stuk in je broek" }
    , { fileName: "Je heb geen gulden hoe ken je nou koopuuh.mp3", title: "Je heb geen gulden hoe ken je nou koopuuh" }
    , { fileName: "Je heb nooit geen geld, kankerboef.mp3", title: "Je heb nooit geen geld, kankerboef" }
    , { fileName: "Je loop meer als werken.mp3", title: "Je loop meer als werken" }
    , { fileName: "Je moet gewoon kijken, hoe de baas het doet hééé.mp3", title: "Je moet gewoon kijken, hoe de baas het doet hééé" }
    , { fileName: "Je moet niet denken dat we dag en nacht.....mp3", title: "Je moet niet denken dat we dag en nacht...." }
    , { fileName: "Je neem me nog in de maling voor die 200euro.mp3", title: "Je neem me nog in de maling voor die 200euro" }
    , { fileName: "Jos, waarom ben je eruit getrapt.mp3", title: "Jos, waarom ben je eruit getrapt" }
    , { fileName: "Kijk mooi weer spelen ken iedereen wel.mp3", title: "Kijk mooi weer spelen ken iedereen wel" }
    , { fileName: "Kijk, je moet je eigen anpassen.mp3", title: "Kijk, je moet je eigen anpassen" }
    , { fileName: "Laat maar komme, gelijk een nik op z'n lijer, ha ha ha ha.mp3", title: "Laat maar komme, gelijk een nik op z'n lijer, ha ha ha ha" }
    , { fileName: "Normaal doen hoor.mp3", title: "Normaal doen hoor" }
    , { fileName: "Nou weet je wat werken is.mp3", title: "Nou weet je wat werken is" }
    , { fileName: "Opkankeren en van m'n zakken uitblijven.mp3", title: "Opkankeren en van m'n zakken uitblijven" }
    , { fileName: "Optieffuhh, gauw!.mp3", title: "Optieffuhh, gauw!" }
    , { fileName: "Tony, wie is Tony.mp3", title: "Tony, wie is Tony" }
    , { fileName: "Tyyffusshoerr.mp3", title: "Tyyffusshoerr" }
    , { fileName: "U ken doorgaan.mp3", title: "U ken doorgaan" }
    , { fileName: "Vieze tyfuslijeerr.mp3", title: "Vieze tyfuslijeerr" }
    , { fileName: "Waar zijn die andere 300 dan.mp3", title: "Waar zijn die andere 300 dan" }
    , { fileName: "We hebben geen tyfus aan die zwerver joh.mp3", title: "We hebben geen tyfus aan die zwerver joh" }
    , { fileName: "We zijn net de soos, kinderen van de soos.mp3", title: "We zijn net de soos, kinderen van de soos" }
    , { fileName: "Ze kennen netjes vragen om volgende maand te betalen.mp3", title: "Ze kennen netjes vragen om volgende maand te betalen" }
    , { fileName: "Zie je, dit zijn allemaal stenen hééé.mp3", title: "Zie je, dit zijn allemaal stenen hééé" }
];

export const mandAudioFiles = [
      { fileName: "Ben je eruit.mp3"}
    , { fileName: "Dronken.mp3"}
    , { fileName: "Een (lidwoord).mp3", title: "Een ('n)"}
    , { fileName: "En zo is het.mp3"}
    , { fileName: "Euro.mp3"}
    , { fileName: "Geef is antwoord.mp3"}
    , { fileName: "Gehad.mp3"}
    , { fileName: "Gewoon.mp3", startNewButtonColor: true}
    , { fileName: "Had je gewoon.mp3"}
    , { fileName: "Had je.mp3"}
    , { fileName: "Had.mp3"}
    , { fileName: "HE.mp3", startNewButtonColor: true}
    , { fileName: "hee 2.mp3"}
    , { fileName: "Hee 3.mp3"}
    , { fileName: "hee 4.mp3"}
    , { fileName: "Hee 5.mp3"}
    , { fileName: "HeEe.mp3"}
    , { fileName: "heeeeh.mp3"}
    , { fileName: "heeh.mp3"}
    , { fileName: "Heh.mp3"}
    , { fileName: "hehh.mp3"}
    , { fileName: "hkeh.mp3"}
    , { fileName: "Hueh.mp3"}
    , { fileName: "Jaaaaaaaa.mp3", startNewButtonColor: true}
    , { fileName: "Je.mp3"}
    , { fileName: "Kamer.mp3"}
    , { fileName: "Kopeeeh.mp3"}
    , { fileName: "Krijg iedereen.mp3"}
    , { fileName: "Kijk.mp3", startNewButtonColor: true}
    , { fileName: "Maar kijk.mp3"}
    , { fileName: "Neus.mp3", startNewButtonColor: true}
    , { fileName: "Oke.mp3"}
    , { fileName: "paardeh.mp3"}
    , { fileName: "tachtig.mp3"}
    , { fileName: "Toniiiiieeeeh.mp3"}
    , { fileName: "Uit.mp3"}
]

export const mashupAudioFiles = [
      { fileName: "Als huisbaas zijnde.mp3"}
    , { fileName: "Bob de huisbaas.mp3"}
    , { fileName: "eeeeeeeehhhhhhhhh.mp3"}
    , { fileName: "HEhehehe.mp3"}
    , { fileName: "Jaaaa je euro krijg iedereen en zo is het ok.mp3"}
    , { fileName: "Jaaaaa ok kijk je uit.mp3"}
    , { fileName: "Kijk je 80 euro krijg iedereen.mp3"}
    , { fileName: "Kijk je neus krijg iedereen.mp3"}
    , { fileName: "Kijk je neus.mp3"}
]

const audioFiles = [
    {
        name: "Standaard",
        files: originalAudioFiles,
        subDir: "standaard/"
    },
    {
        name: "Mand",
        files: mandAudioFiles,
        subDir: "mand/"
    },    {
        name: "Mashup",
        files: mashupAudioFiles,
        subDir: "mashup/"
    },
];

audioFiles.forEach(audioSet => {
    audioSet.files.forEach(file => {
        file.src = audioBasePath + (audioSet.subDir || "") + file.fileName;
        if (!file.title) {
            file.title = file.fileName.replace('.mp3', '');
        }
    });
});

export default audioFiles;