import React from 'react';

export function TextBlock(props) {
    const TagName = props.tag || 'p';
    let {className, ...extraProps} = props;
    return (
        <TagName className={`text-white text-black-border text-no-select ${className || ''}`} {...extraProps} >
            {props.children}
        </TagName>
    );

}