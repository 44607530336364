import React from 'react';
import './AppBackground.scss';
import backgroundImg from './background.jpg'
import backgroundLaserImg from './background-laser.jpg'

export function AppBackground(props) {
    // restart rotating bob head on click, using double animation technique from https://css-tricks.com/restart-css-animation/
    // fun effect when browser does not show lasers (mobile firefox?)
    // update: Removed this because it still does not work in FF mobile, maybe the event is not fired? see App.js
    //         and laser img replacement also resets animation
    return (
        <div className="AppBackground">
            <div style={{ display: "none" }}>
                {/* 
                preload laser image so it works the first time 
                https://serversideup.net/how-to-preload-css-background-images/ 
                */}
                <img src={backgroundImg} alt="" />
                <img src={backgroundLaserImg} alt="" />
            </div>
            <div className={`background ${props.useLaser ? "background__laser" : ""}`} >
                <div className={`background-inner`}></div>
            </div>
        </div>
    );
}
