import React from 'react';
import './ShareButtons.scss';

// from https://www.flaticon.com/packs/social-networks-logos-2
import whatsappLogo from './whatsapp.svg';
import facebookLogo from './facebook.svg';
import twitterLogo from './twitter.svg';
import { TextBlock } from '../TextBlock';

const buttons = [
    {
        name: "Whatsapp",
        logo: whatsappLogo,
        url: "https://wa.me/?text=" + encodeURI("Wat zegt Huisbaas Bob? " + window.location.href)
    },
    {
        name: "Facebook",
        logo: facebookLogo,
        url: "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(window.location.href)
    },
    {
        name: "Twitter",
        logo: twitterLogo,
        url: `https://twitter.com/intent/tweet?text=${encodeURI("Wat zegt Huisbaas Bob?")}&url=${encodeURI(window.location.href)}`
    },
];

export function ShareButtons() {

    return (
        <div className="ShareButtons">
            <TextBlock className="instruction">Deel Bob met de wereld&thinsp;!</TextBlock>
            <p className="buttons">
            {buttons.map(button =>
                    <a key={button.name}
                       href={button.url} target="_blank" 
                       className="btn btn-light btn-transparent">
                        <img src={button.logo} alt={button.name} title={`Deel via ${button.name}`} />
                        <span>{button.name}</span>
                    </a>
            )}
                </p>
        </div>
    );
}